import React from "react";

function Hero() {
  return (
    <section className="inquiryHero">
      <div className="inquiryHero__space">
        <h1 className="inquiryHero__title">お問い合わせ</h1>
        <p className="inquiryHero__para">
          ご利用のインターネット環境によりフォームが表示されない場合がございます。
          もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせください。
          <a href='mailto:info@digitalaccels.com'>
              info@digitalaccels.com
          </a>
          <br />
          <span>
              *この項目は入力する必要があります。
          </span>
        </p>
      </div>
    </section>
  );
}

export default Hero;
