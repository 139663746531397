import React from "react";
import Container from "@layout/container";
import Hero from "@components/inqury/hero";
import Meta from "../components/seo/Meta";
import { graphql, Script } from "gatsby";

function inquiry(props) {
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harnessのお問い合わせ｜Harness正規販売代理店 DXable`,
    description: `Harnessは、アジャイル開発に必須のAIドリブンなCI/CDプラットフォームです。詳細はぜひお問い合わせください。`,
    keywords: `Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CI,Cntinuous Integration,継続的インテグレーション,CD,Continuous Delivery,継続的デリバリー,Feature Flags,機能フラグ,CCM,クラウドコスト管理,Harness代理店,お問い合わせ`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <Hero></Hero>
        <div className="hn-container">
          <div className="contactWrapper">
            <div className="contactWrapper__form">
              <div id="contact-form">
                <Script id={`freshworksform-${new Date().getTime()}`} dangerouslySetInnerHTML={{ __html: `
    var sc = document.createElement('script');
    sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86/form.js'
    sc.crossOrigin = 'anonymous'
    sc.id = 'fs_6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86'
    document.getElementById('contact-form').appendChild(sc);
    new MutationObserver(function(mutationsList, observer) {
        mutationsList.forEach(mutation => {
            if (mutation.addedNodes) {
              var inputElements = document.getElementsByName('contact[custom_field][cf_web_form]');
              if (inputElements.length > 0) {
                inputElements[0].value = 'https://harness.dxable.com/inquiry/';
                var parent = inputElements[0].parentNode;
                parent.classList.add('fserv-hidden');
              }
            }
        });
    }).observe(document.getElementById('contact-form'), { childList:true, subtree: true });
          ` }} />
              </div>
              <p><a target="_blank" href="https://www.digitalaccels.com/privacy-policy">個人情報保護方針</a>についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export const inquiryPageData = graphql`
  query fetchInquiryPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default inquiry;
